import React from 'react'
import { AuthCheck } from 'reactfire'

import FireChatAuth from '../components/FireChatAuth'
import SignOut from '../components/SignOut'

import Chat from '../components/chat/Chat'
import Layout from '../components/Layout'

const RadioIndex = () => {
  return (
    <Layout>
      <center>
        <header>
          <img
            src="https://radiolagauchita.com.ar/static/logo-4d9c22a4448987ed3a2862663443df49.png"
            alt=""
          />
        </header>

        <br />

        <iframe
          allow="autoplay"
          title="reproductor"
          src="https://server.radiolagauchita.com/public/la_gauchita/embed?theme=light"
          frameborder="0"
          allowtransparency="true"
          class="player"
          autoplay="true"
          width="90%"
          height="auto"
          style={{
            backgroundColor: 'rgba(201,149,53,.808)',
            borderRadius: ' 25px',
            padding: '8px',
          }}
        />

        <div class="menu-bar">
          <ul>
            <li>
              <a aria-current="page" class="active-link" href="/">
                Inicio
              </a>
            </li>
            <li>
              <a href="https://eduardoceballos.com">Eduardo Ceballos</a>
            </li>
          </ul>
        </div>

        <h1>Bienvenido a Radio La Gauchita.</h1>
        <h3>
          Facebook:{' '}
          <a href="https://www.facebook.com/radiolagauchita">
            https://www.facebook.com/radiolagauchita
          </a>
        </h3>
        <h3>
          Correo:{' '}
          <a href="mailto:eduardoceballos.salta@gmail.com">
            eduardoceballos.salta@gmail.com
          </a>
        </h3>
      </center>
      <AuthCheck fallback={<FireChatAuth />}>
        <Chat></Chat>
        <center>
          <SignOut />
        </center>
        <br /> <br />
      </AuthCheck>
    </Layout>
  )
}

export default RadioIndex
