import React from 'react'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'

import SendMessage from './SendMessage'

import './chat.css'

const Chat = () => {
  const chatRef = useFirestore()
    .collection('chat')
    .orderBy('createdAt', 'desc')
    .limit(20)
  const chat = useFirestoreCollectionData(chatRef)

  return (
    <div className="chat-app">
      <div className="message-renderer">
        {chat.map(x => {
          return (
            <div className="message">
              <img
                src={x.picture ? x.picture : '/default-profile-picture.png'}
                alt="Foto de perfil"
              />
              <p uid={x.uid}>
                <strong>{x.name}:</strong> {x.message}
              </p>
            </div>
          )
        })}
      </div>
      <SendMessage />
    </div>
  )
}

export default Chat
