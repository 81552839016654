import React from 'react'
import { Formik, Field, Form } from 'formik'

import { useUser, useFirestore } from 'reactfire'

import './chat.css'

const SendMessage = () => {
  const user = useUser()

  const firestore = useFirestore()

  function sendMessage(message) {
    firestore.collection('chat').add({
      uid: user.uid,
      name: user.displayName,
      email: user.email,
      picture: user.photoURL,
      createdAt: Date.now(),
      message,
    })
  }

  return (
    <div className="message-form">
      <Formik
        initialValues={{ message: '' }}
        // onSubmit={async values => sendMessage(values.message)}
        onSubmit={async (values, { resetForm }) => {
          sendMessage(values.message)
          resetForm({ values: '' })
        }}
      >
        <Form>
          <Field id="message" name="message" placeholder="Mensaje" />
          <button type="submit">Enviar Mensaje</button>
        </Form>
      </Formik>
    </div>
  )
}

export default SendMessage
